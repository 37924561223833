.landing-about-container {
  padding: 100px 0;
  .landing-about-heading {
    font-family: var(--kanit);
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 700;
    .heading-gradient {
      background-image: var(--main-grad);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .landing-about-sub-heading {
    font-family: var(--roboto);
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 200;
  }
  .landing-about-body {
    display: flex;
    margin-top: 50px;
    .landing-body-box {
      padding: 50px 50px 50px 50px;
      position: relative;
      z-index: 1;
      margin-bottom: 30px;
      .landing-body-box-icon {
        svg {
          width: 30px;
        }
      }
      .landing-body-box-heading {
        font-family: var(--kanit);
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 10px;
        color: var(--sec-head);
      }
      .landing-body-box-text {
        font-size: 16px;
        font-family: var(--roboto);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      &::before {
        content: "";
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-image: linear-gradient(
          150deg,
          #ffffff 100%,
          var(--e-global-color-ed2726d) 100%
        );
        background-color: #fff;
        box-shadow: 0px 0px 12.61px 0.39px rgba(0, 0, 0, 0.14);
        transform: scale3d(1.05, 1.05, 1.05);
        transition: all 500ms ease;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        opacity: 0;
        background-image: var(--main-grad);
        box-shadow: 0px 0px 12.61px 0.39px rgba(0, 0, 0, 0.14);
        transition: all 500ms ease;
        transform: scale3d(1.05, 1.05, 1.05);
      }
      &:hover {
        color: white;
        .landing-body-box-heading {
          color: white;
        }
        svg {
          fill: #ffffff;
        }
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}
