* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --brand-bg: #fad6be;
  --e-global-color-accent: #83b6ff;
  --e-global-color-044c953: #e400f7;
  --kanit: "Kanit", sans-serif;
  --roboto: "Roboto", sans-serif;
  --sec-head: #83b5ff;
  --main-grad: linear-gradient(
    150deg,
    var(--e-global-color-accent) 1%,
    var(--e-global-color-044c953) 100%
  );
}

.main-button {
  padding: 10px 25px 10px 25px;
  font-family: var(--roboto);
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: transparent;
  background-image:var(--main-grad);
  border-radius: 0px 0px 0px 0px;
  color: white;
  font-size: 16px;
  border-radius: 130px;
}

.alternate-button {
  padding: 5px 20px 5px 20px;
  font-family: var(--roboto);
  text-transform: uppercase;
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: white;

  border-radius: 0px 0px 0px 0px;
  color: black;
  font-size: 16px;
  border-radius: 150px;
}

.over-line {
  width: 60px;
  height: 2px;
  background-color: white;
}


.sec-header-line{
  height: 2px;
  background-color: var(--sec-head);
  width: 50px;
  margin-left: 15px;
}