.landing-page-container {
  .landing-upper {
    z-index: 1;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 70%;
      width: 100%;
      z-index: -1;
      background-image: url("../../Assets/Images/Layout/newTriangle.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .bottom-edge {
    z-index: 1;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-image: url("../../Assets/Images/Layout/newTriangle.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .landing-page-header {
  }
  .landing-hero {
    display: flex;
    height: calc(100vh - 100px);
    // background-image: var(--main-grad);
    position: relative;

    // flex-wrap: wrap;
    .landing-hero-image {
      padding: 0 5px;
      .react-parallax {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: initial;
        z-index: 5;
        .parallax-inner-div {
          position: absolute;
          left: 0%;
          top: 0%;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 500ms ease;
          z-index: 5;
        }
      }
      &:nth-child(2) {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .landing-hero-box {
      position: absolute;
      right: 100px;
      top: 50%;
      background-image: var(--main-grad);
      max-width: 550px;
      padding: 45px 60px 45px 60px;
      transform: translateY(-50%);
      border-radius: 20px;
      z-index: 6;
      .landing-hero-inner-box {
        .landing-hero-box-text {
          font-family: var(--kanit);
          font-size: 62px;
          font-weight: 700;
          color: white;
          line-height: 0.9;
          margin-bottom: 40px;
        }

        .landing-hero-button {
          button {
          }
        }
      }
    }
  }
}
