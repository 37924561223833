.landing-team-contrainer {
  padding: 100px 0;
  background-image: var(--main-grad);
  .landing-team-heading {
    font-family: var(--kanit);
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;
    span{
      background-color: #fff;
    }
  }
  .landing-team-subheading {
    font-family: var(--roboto);
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 200;
    color: #fff;
  }
}
.landing-team-body {
  margin-top: 50px;
  .landing-team-box {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    img {
      width: 170px;
      height: 170px;
      object-fit: cover;
      position: relative;
      border-radius: 50%;
      margin-bottom: 10px;
      border: 2px solid transparent;
      transition: all 500ms ease;

      &:hover{
        border: 2px solid #fff;
        transform: scale(1.1);
      }
    }
    .landing-team-name {
      font-family: var(--kanit);
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      margin-bottom: 10px;
    }
    .landing-team-role {
      font-size: 16px;
      font-family: var(--roboto);
      margin-bottom: 10px;
      color: #fff;
    }

    .landing-team-bottom-line {
      background-color: #fff;
      height: 2px;
      width: 40px;
    }

    &:hover {
   
      .landing-team-bottom-line {
        background-color: #fff;
      }
      svg {
        fill: #ffffff;
      }
    }
  }
}
