.footer-container {
  background-image: var(--main-grad);
  padding: 100px 0 0px;
  font-family: var(--roboto);
  .footer-logo {
    width: 200px;
    margin-left: -10px;
    margin-top: -10px;
  }
  .footer-about {
    color: #fff;
  }
  .footer-heading {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: var(--kanit);
    font-size: 24px;
    margin-bottom: 30px;
    svg {
      width: 25px;
      margin-right: 10px;
      fill: #fff;
    }
  }
  .footer-item {
    color: #fff;
    font-size: 14px;
  }
  .footer-media {
    svg {
      color: #fff;
      margin-right: 10px;
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 30px 0;
    margin-top: 100px;
    border-top: 1px solid #ccc;
    font-size: 14px;
    .footer-bottom-left {
    }

    .footer-bottom-right {
    }
  }
}
