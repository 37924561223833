.landing-works-container {
  padding: 70px 0;
  .landing-works-heading {
    font-family: var(--kanit);
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 700;
    color: var(--sec-head);
    display: flex;
    align-items: center;
  }
  .landing-works-subheading {
    font-family: var(--roboto);
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 200;
    color: #000;
  }
  .landing-works-body {
    margin-top: 50px;

    .landing-works-tab-container {
      //   display: flex;
      //   flex-wrap: wrap;
      column-count: 3;
      column-gap: 10px;
      .wd-image-container {
        position: relative;
        width: 100%;
        // display: flex;
        // justify-content: center;
        position: relative;
        z-index: 0;
        margin-top: 10px;
        cursor: pointer;
        .wd-overlay {
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          transition: all 250ms ease;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.7);
          opacity: 0;
          position: absolute;
        }

        &:hover {
          .wd-overlay {
            opacity: 1;
          }
          .wd-detail {
            left: 0;
            bottom: 0;
            transform: translatex(0);
            width: 100%;
            background-color: transparent;
          }
        }
        img {
          width: 100%;
          vertical-align: middle;
        }
        .wd-detail {
          position: absolute;
          bottom: 10px;
          background-color: rgba(0, 0, 0, 0.7);
          color: #fff;
          width: 90%;
          padding: 10px 10px;
          left: 50%;
          z-index: 2;
          transition: all 500ms ease;
          transform: translateX(-50%);

          .wd-heading {
            font-family: var(--kanit);
            font-size: 24px;
            text-transform: capitalize;
          }
          .wd-info {
            font-family: var(--roboto);
          }
        }
      }
    }
  }
}
