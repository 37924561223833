.landing-video-container {
  background-image: url("https://agni.wpengine.com/wp-content/uploads/2022/07/section-bgimage4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .landing-video-play {
    --spacer-size: 110px;
    width: 110px;
    max-width: 110px;
    margin-bottom: 30px;
    .play-inner {
      -webkit-mask-image: url(https://agni.wpengine.com/wp-content/uploads/2022/09/play-button.png);
      -webkit-mask-size: contain;
      -webkit-mask-position: center center;
      -webkit-mask-repeat: no-repeat;
      mask-image: url(https://agni.wpengine.com/wp-content/uploads/2022/09/play-button.png);
      mask-size: contain;
      mask-position: center center;
      mask-repeat: no-repeat;

      margin: 0px 0px 0px 0px;
      --e-transform-transition-duration: 500ms;
      background-color: transparent;
      background-image: radial-gradient(
        at center center,
        #83b6ff 0%,
        #e400f7 100%
      );
      transition: all 500ms ease;
      &:hover {
        --e-transform-rotateX: 1deg;
        --e-transform-perspective: 488px;
        --e-transform-scale: 0.85;

        background-color: transparent;
        background-image: radial-gradient(
          at center center,
          #83b6ff 0%,
          #e400f7 100%
        );
        box-shadow: 0px 0px 12.61px 0.39px rgba(255, 255, 255, 0.14);
        transform: rotateX(1deg) scale(0.85) perspective(488px);
        .play-spacer {
          --e-transform-rotateX: 1deg;
          --e-transform-perspective: 488px;
          --e-transform-scale: 0.85;
          transform: rotateX(1deg) scale(0.85) perspective(488px);
        }
      }
      .play-spacer {
        height: 110px;
      }
    }
  }
  .landing-video-heading {
    font-family: var(--kanit);
    font-size: 52px;
    text-transform: capitalize;
    font-weight: 700;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 30px;
  }
  .landing-video-bottom {
    color: white;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: var(--roboto);
    cursor: pointer;

    span {
      &:hover {
        color: var(--sec-head);
        svg {
          fill: var(--sec-head);
        }
      }
    }
    span {
      margin-right: 30px;
    }
    svg {
      width: 20px;
      fill: white;
      margin-left: 5px;
    }
  }
}
