.landing-affiliation-container {
  padding: 50px 0 70px;
  .landing-affiliation-heading {
    font-family: var(--kanit);
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 700;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    align-items: center;
  }
  .landing-affiliation-sub-heading {
    font-family: var(--roboto);
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 200;
  }
}
.landing-affiliation-body {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  .landing-affiliation-item {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    flex-grow: 1;
    img {
      height: 50px;
      object-fit: contain;
    }
  }
}
