.nav-container {
  height: 100%;
  .nav-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .single-nav-item {
      font-family: var(--roboto);
      cursor: pointer;
      a {
        text-decoration: none;
        color: inherit;
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      }
      &:hover {
        color: var(--sec-head);
      }
    }
  }
  .nav-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .nav-right-contact {
      display: flex;
      align-items: center;
      .nav-right-icon {
        padding: 0 10px 0px 0;
        border-right: 1px solid grey;
        svg {
          width: 40px;
          fill: #83b5ff;
        }
      }
      .nav-right-contact-text {
        font-size: 16px;
        padding-left: 10px;
        font-family: var(--roboto);
        .top {
          color: grey;
        }

        .bottom {
          font-weight: 700;
        }
      }
    }
    .nav-right-button {
      font-size: 16px;
    }
  }
}
