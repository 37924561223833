.landing-blog-container {
  padding: 70px 0;
  .landing-blog-heading {
    font-family: var(--kanit);
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 700;
    color: var(--sec-head);
    display: flex;
    align-items: center;
  }

  .landing-blog-subheading {
    font-family: var(--roboto);
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 200;
    color: #000;
  }
  .landing-blog-body {
    display: flex;
    // grid-gap: 30px;
    margin-top: 70px;
    .long-grid {
      // grid-column: 1/2;
      // grid-row: 1/3;
      flex: 1 1 50%;
      box-shadow: 0 0 12.61px 0.39px rgba(0, 0, 0, 0.14);
      .long-grid-image {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .long-grid-body {
        padding: 30px 35px;
      }
    }
    .right-top-grid {
      grid-column: 2/2;
      grid-row: 1/2;
    }
    .right-bottom-grid {
      grid-column: 2/2;
      grid-row: 2/3;
    }
    .small-grids-container {
      flex: 1 1 50%;
      margin-left: 30px;
      .small-grid {
        margin-bottom: 50px;
        .small-grid-image {
          width: 100%;
          position: relative;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
          .grid-date {
            position: absolute;
            bottom: 3px;
            left: 10px;
          }
        }
      }
    }
    .grid-date {
      svg {
        margin-right: 10px;
        fill: #fff;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: var(--main-grad);
      padding: 10px 10px;
      width: 160px;
      color: #fff;
      margin-bottom: 10px;
      font-family: var(--roboto);
      font-size: 14px;
    }
    .grid-heading {
      font-family: var(--kanit);
      font-size: 22px;
      text-transform: capitalize;
      font-weight: 700;
      color: #000;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .grid-body {
      margin-bottom: 20px;
      font-family: var(--roboto);
      font-size: 15px;
    }
    .grid-bottom {
    }
  }
}
