.preloader-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .preloader-body {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f6f6f6;
    transition: all 500ms ease;
    transform: translateY(0);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .preloader-off {
    transform: translateY(-110%);
  }
  .remain-body {
    position: relative;
    z-index: 3;
    width: 100vw;
    overflow-x: hidden;
  }
}
